import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { IoIosWarning } from "react-icons/io";

import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahLogo_Full from "../images/logos/Droppah/Droppah_Full_White.svg";

const ErrorMessage = styled.h4`
  svg {
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 7px;
    color: #ffd62e;
  }
`;

const NotFoundPage = (props) => (
  <Layout fullPage>
    <Seo
      title="Oops... Page Not Found | 404"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      <Wrapper maxWidth={800}>
        <img
          className="-center"
          src={DroppahLogo_Full}
          alt="Droppah | Rosters, Time Tracking & Attendance Software"
          height="50"
        />
        <Box stackGap={35} className="-textCenter">
          <div>
            <h1 className="-fontLarge">Oops!</h1>
            <h2 className="-fontLight">
              The page you're looking for doesn't exist{" "}
              <span role="img" aria-label="404 Page Not Found | Sad">
                😢
              </span>
            </h2>
          </div>
          <ErrorMessage>
            <span className="-fontBold">
              <IoIosWarning />
              Error:
            </span>{" "}
            404
          </ErrorMessage>
          <Link className="-center link-floating" to="/">
            Go Home
          </Link>
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default NotFoundPage;
